@media (max-width: 1000px) {
  .mobile{
    display: flex !important;
  }
  .desktop{
    display: none !important;
  }
  #background {
    max-width: none;
  }
}

@media (min-width: 1000px) {
  .mobile{
    display: none !important;
  }
  .desktop{
    display: flex !important;
  }
  #background {
    /* max-width: 100%; */
    max-width: none;
  }
}

#background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

/* Add some content at the bottom of the video/page */
.App {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}


.App {
  text-align: center;
  height: 100dvh;
  color: white;
  /* background-image: url('./assets/backgrounds/background-pink.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container{
  height: 80dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.App-header {
  color: white;
  height: 20dvh;
}

.header{
  padding: 20px;
  padding-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  margin-bottom: 35px;
}

.mobile-header{
  padding: 20px;
  padding-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.dropdown-item{
margin: 5px;
}


.header .btn {
 text-decoration: none;
 color: white;
 font-weight: bold;
 background: none;
 border: none;
 font-size: large;
}

.mobile-header .btn {
  text-decoration: none;
  color: #da4993;
  font-weight: bold;
  background: none;
  border: none;
  font-size: large;
 }

/* ticker stuff  */
.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 400s linear infinite;
}

.marquee2 span {
  animation-delay: 200s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.info-ticker{
  font-weight: bold;
  color: white;
}

.ticker-spacer {
  height: 50px;
    border-top: 2.5px solid #5b3b1a;
    border-bottom: 2.5px solid #5b3b1a;
    margin-top: -15px;
}

.mobile-banner-text{
  display: flex;
}

.header-forms-container{
  width: 100%;
  position: absolute;
  z-index: 1;
}

.mobile-header-forms-container{
  width: 90%;
  position: absolute;
  z-index: 1;
  margin-top: 15%;
}

.submit-form {
  display: flex;
  justify-content: space-between;
  background-color: #DFD4C7;
  color: #5b3b1a;
  opacity: 60%;
}

.form-header{
  font-size: 5em;
  padding-left: 20px;
}

.form-input-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.form-input-container label {
  padding-right: 20px;
}

.form-input-container input {
  background: none;
  border: 1px solid white;
  font-size: 1.5em;
  min-width: 30em;
}

.form-cta-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.form-cta{
  background: none;
  border: 1px solid #5b3b1a;
  font-weight: bold;
  margin-right: 20px;
  color: #5b3b1a;
  font-size: 1em;
  min-width: 5em;
}

/* mobile uses modal instead */
.modal{
  background-color: #DFD4C7;
  color: #5b3b1a;
}

.modal-header{
  color: #da4993;
}

.radio-pink{
  color: #da4993;
}

/* .modal-content{
  background-color: #DFD4C7;
} */

.modal.submit-form {
  opacity: 100%;
  background: none;
}

.mobile-form-cta{
  background-color: #5b3b1a;
  color: white;
  font-weight: bolder;
}

.modal-body .form-input-container label{
 text-align: left;
}

.modal-body .form-input-container input{
  border: 1px solid #5b3b1a;
  min-width: 100%;
  width: 100%;
 }

/* Schedule */
.schedule-events-container{
display: flex;
}

.schedule-current-time{
  font-size: 2em;
  font-weight: bolder;
  text-align: end;
}

.event{
  margin: 0 0 0 24px ;
}

.schedule-container{
  padding-right:32px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.mobile-schedule-events-container{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.mobile-schedule-current-time{
  font-size: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

/* Logo */
.logo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.logo-wrapper {
  position: relative;
  max-width: 95%;
}
.logo-wrapper img.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity .2s;
}
.logo-wrapper:hover img.image-hover {
  opacity: 1;
}

.mobile-logo {
  width: 95%;
  display: flex;
  justify-content: center;
  border: 1px solid white;
  border-radius: 30px;
  margin: 0px 10px 20px 10px;
}
.mobile-logo .logo-wrapper {
  position: relative;
  max-width: 85%;
  margin: 10px 0px 10px 0px;
}

/* radio player */
.radio-container{
  
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #5b3b1a;
  font-size: 8em;
  font-weight: bold;
}

.player-container{
  background: none;
  border: none;
  font-size: 1em;
  color: #da4993;
}